.grid-container {
  padding: 0.5rem;
  padding-bottom: 0;
  /* padding-top: 1rem;
  flex: 1 1 0; */
}

.fullpage {
  height: calc(100vh - var(--headerSize));
}

.fullpage.noScroll {
  overflow: hidden;
}

.fullpage.scroll {
  overflow: auto;
}

.dashboardMain {
  box-shadow: 2px 0 4px -1px rgb(0 0 0 / 20%), 4px 0 5px 0 rgb(0 0 0 / 14%), 1px 0 10px 0 rgb(0 0 0 / 12%);

}

.fullHeight {
  min-height: 100vh;
}

.menuSidebar,
.sectionContent {
  overflow: auto;
}

.sectionContent {
  background-color: #fff;
  border-top: 1px solid rgb(234, 234, 234);
}

.content-wrapper {
  max-width: 1600px;
  width: 100%;
  display: block;
  margin: 0 auto;

}

.font-light {
  color: #637381 !important;
}

/* pop up notification */
.popup-notification p {
  margin: 0;
}
.popup-notification .title {
  font-size: 20px;
  font-weight: bold;
  color: #343a40;
}
.popup-notification .time {
  color: #919eab;
  font-size: 14px;
  font-weight: bold;
}
.popup-notification .message {
  color: #637381;
}
.popup-notification .message-container {
  padding: 1rem 0;
}
.popup-notification .message-container:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.popup-notification .message-container:last-child {
  padding-bottom: 0;
}
.popup-notification .message-container.new {
  background-color: rgba(145, 158, 171, 0.16);
  border-bottom-color: #fff;
}
/* pop up notification */

.contracts-table {
  width: 100%;
}
.contracts-table tbody tr:nth-child(even) {
    background-color: #eeeeee;
}

.contracts-table-container {
  overflow-y: auto;
  /*flex-basis: 300px;*/
  flex-basis: calc(30vh);
  display: flex;
  gap: 1rem;
  flex-direction: column;
}