@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Black.ttf");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}