.menuIcon {
  width: 100%;
  min-width: 30px;
  max-width: 30px;
  object-fit: contain;
}

.sidebarWrapper a.active {
  color: black;
  text-decoration: none;
}
.sidebarWrapper a {
  padding: 0.5rem;
  margin: 0.5rem;
}
.sidebarWrapper a:hover {
  padding: 0.5rem;
  border-left: 2px solid #733dc4;
  margin: 0.5rem;
}
