.form-box .card {
  width: 100%;
  min-width: 450px;
  max-width: 550px;
}
.form-box .card form {
  margin-top: 2rem;
}
.form-box .card .p-field {
  margin-bottom: 1.5rem;
}

.form-box .submitBtnBox {
  width: 100%;
  max-width: 300px;
  display: block;
  margin: 0 auto;
}

.form-box .forgotPassword {
  margin-top: -1rem;
}

@media screen and (max-width: 960px) {
  .form-box .card {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .form-box .card {
    width: 100%;
    min-width: 0;
  }
}

.fpassword {
  color: #6366f1;
  cursor: pointer;
  text-align: right;
  margin-top: 10px;
  font-size: 15px;
}

.fpassword:hover {
  color: #0005fe;
  cursor: pointer;
  text-align: right;
  margin-top: 10px;
}

.regitertext {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
}
.regitertext span {
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  color: #0056b3;
  font-size: 15.5px;
}
.regitertext span:hover {
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  color: #6366f1;
}
